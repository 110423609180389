const generatePermissions = (servicer, effectiveUser) => {
  return {
    hasDisclosuresEnabled: servicer.has_disclosures_enabled,
    hasCreditReports: servicer.has_credit_reports,
    hasMyLoan: servicer.has_myloan,
    allowCompletedTasksDelete: servicer.allow_completed_tasks_delete,
    allowLoanAppAccess: servicer.allow_loan_app_access,
    allowLoanAppEdit: servicer.allow_loan_app_edit,
    allowLoanDocDelete: servicer.allow_loan_doc_delete,
    allowLoanAppReturn: servicer.allow_loan_app_return,
    chatAllowed: servicer.chat_allowed,
    chatEnabled: servicer.chat_enabled,
    hasPrequalOnLoans: effectiveUser?.__typename === 'TeamMember' ? effectiveUser?.team_member_feature_setting?.allow_prequal_on_loans : servicer.has_prequal_on_loans,
    hasPreApprovalOnLoans: effectiveUser?.__typename === 'TeamMember' ? effectiveUser?.team_member_feature_setting?.allow_prequal_on_loan_apps : servicer.has_pre_approval_on_loans,
    hasPrequalOnLoanApps: effectiveUser?.__typename === 'TeamMember' ? effectiveUser?.team_member_feature_setting?.allow_pre_approval_on_loans : servicer.has_prequal_on_loan_apps,
    hasPreApprovalOnLoanApps: effectiveUser?.__typename === 'TeamMember' ? effectiveUser?.team_member_feature_setting?.allow_pre_approval_on_loan_apps : servicer.has_pre_approval_on_loan_apps,
    allowAdminsToDeleteLoanApps: servicer.feature_setting.allow_admins_to_delete_loan_apps,
    allowAdminsToSignDisclosures: servicer.disclosure_setting.allow_admins_to_sign_disclosures_for_servicer,
    allowSingleLoanApp: servicer.feature_setting.allow_single_loan_app,
    nexusPayTaskEnabled: servicer.borrower_task_setting.nexus_pay_task_enabled,
    allowNexusPayRequests: effectiveUser?.borrower_task_setting.allow_nexus_pay_requests,
    allowCustomPaymentRequests: effectiveUser?.borrower_task_setting.allow_custom_payment_requests,
    borrowerLoanDocViewEnabled: servicer.feature_setting.borrower_loan_doc_view_enabled,
    oneOffEsignDocuments: servicer.feature_setting.send_one_off_esign_package,
    requireDisclosures2FA: servicer.company?.require_disclosures_2fa,
    allowLoanInfoEdit: servicer.allowLoanInfoEdit,
    hasSendAppUserWelcomeEmails: servicer.notification_setting.send_welcome_emails_for_app_users,
    allowRefiningVoaOnRefresh: servicer.voa_setting.refineRefreshedReportsEnabled,
    allowRefreshingVerificationOrders: servicer.voa_setting.refresh_enabled,
    voieEnabled: servicer.voa_setting.voie_enabled,
    orderCreditEnabledForLoan: servicer.feature_setting.order_credit_enabled_for_loan,
    orderCreditEnabledForLoanApp: servicer.feature_setting.order_credit_enabled_for_loan_app,
    oneOffAllowThirdPartyRecipient: servicer.feature_setting.one_off_allow_third_party_recipient,
    lpaFreddieMacRequest: servicer.feature_setting.lpa_freddie_mac_request,
    hasLo1003Enabled: servicer.feature_setting.has_lo_1003_enabled,
    hasLoanDetailsV2Enabled: servicer.feature_setting.has_loan_details_v2_enabled,
    ausEnabled: servicer.aus_setting.aus_enabled,
    duEnabled: servicer.has_du,
    lpaEnabled: servicer.has_lpa,
    filterableFields: servicer.ob_setting.filterable_fields,
    completeNexusPayPaymentOnBehalfOfBorrower: effectiveUser?.borrower_task_setting.complete_nexus_pay_payment_on_behalf_of_borrower,
    processNexusPayRefunds: effectiveUser?.borrower_task_setting.process_nexus_pay_refunds,
    allowRequestingNexusPayPaymentToInactiveLoans: servicer.feature_setting.allow_requesting_nexus_pay_payment_to_inactive_loans,
    loanActivityDetailsLog: servicer.nexus_teams_setting.loan_details_activity_log,
    partnersEnabled: servicer.feature_setting.partners_enabled,
    allowInvitingNewPartnersToLoans: servicer.feature_setting.allow_inviting_new_partners_to_loans,
    requireItpForCustomNexusPayRequests: effectiveUser ? effectiveUser?.borrower_task_setting.require_itp_for_custom_nexus_pay_requests : servicer.borrower_task_setting.require_itp_for_custom_nexus_pay_requests,
    removeManualCreditAuthorization: servicer.feature_setting.remove_manual_credit_authorization,
    pricingEnabled: servicer.feature_setting.pricing_on_web_enabled,
    obUserSynced: servicer.has_ob_pricing_credentials,
    hasObPricing: servicer.ob_setting.has_ob_pricing,
    hasPollyPricing: servicer.has_polly_pricing,
    allowLoToggleLoanDocVisibility: servicer.borrower_task_setting.allow_lo_toggle_loan_doc_visibility,
    showClosingCostCard: servicer.feature_setting.show_closing_cost_card,
    verificationsUiV2Enabled: servicer.feature_setting.verifications_ui_v2_enabled,
    teamMembersEnabled: servicer.feature_setting.team_members_enabled,
    primaryLoaEnabled: servicer.nexus_teams_setting.primary_loa,
    loanNotificationLogEnabled: servicer.release_flag.loan_notification_log,
    loMultiBorrowerSupport: servicer.release_flag.lo_multi_borrower_support
  }
}

export default generatePermissions
