
import isEmpty from 'lodash/isEmpty'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    loanAppActionsMixin_loanApp: {
      type: Object,
      default: () => {}
    },
    loanAppActionsMixin_loadingQuery: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loanAppActionsMixin_showLoanAppPreview: false,
      loanAppActionsMixin_showDownloadFormatsModal: false,
      loanAppActionsMixin_showSendLoanAppToLosModal: false,
      loanAppActionsMixin_showConvertToLoanModal: false,
      loanAppActionsMixin_showDeleteLoanAppModal: false,
      loanAppActionsMixin_showRequestRevisionModal: false,
      loanAppActionsMixin_showSendReminderModal: false,
      loanAppActionsMixin_taskEventId: null
    }
  },
  computed: {
    ...mapGetters({
      loanAppActionsMixin_permissions: 'getPermissions',
      loanAppActionsMixin_loanOwnerV2: 'getLoanOwner2',
      loanAppActionsMixin_servicerId: 'getServicerId',
      loanAppActionsMixin_servicerActivationCode: 'getServicerActivationCode',
      loanAppActionsMixin_selectedLoanAppGuid: 'getSelectedLoanAppGuid',
      loanAppActionsMixin_selectedLoan: 'getSelectedLoan',
      loanAppActionsMixin_isRemoteBorrower: 'getIsRemoteBorrower',
      loanAppActionsMixin_losName: 'getLosName',
      loanAppActionsMixin_currentUserAccountType: 'getCurrentUserAccountType',
      loanAppActionsMixin_servicerGuid: 'getServicerGuid'
    }),
    loanAppActionsMixin_loanApp_relevantValues () {
      if (!this.loanAppActionsMixin_loanApp?.relevant_values) return null
      return JSON.stringify(JSON.parse(this.loanAppActionsMixin_loanApp?.relevant_values), null, 2) || null
    },
    loanAppActionsMixin_loanApp_loanAppJson () {
      if (!this.loanAppActionsMixin_loanApp?.relevant_values) return null
      return JSON.stringify(JSON.parse(this.loanAppActionsMixin_loanApp?.loan_app_json), null, 2) || null
    },
    loanAppActionsMixin_loanOwner () {
      return this.loanAppActionsMixin_loanOwnerV2(this.loanAppActionsMixin_loanAppGuid)
    },
    loanAppActionsMixin_loanId () {
      return this.loanAppActionsMixin_selectedLoan?.id || ''
    },
    loanAppActionsMixin_loanGuid () {
      return this.loanAppActionsMixin_selectedLoan?.guid || ''
    },
    loanAppActionsMixin_loanAppGuid () {
      return this.loanAppActionsMixin_loanApp?.guid || ''
    },
    loanAppActionsMixin_loanAppActionHref () {
      if ((this.loanAppActionsMixin_hasCompletedLoanApplication && !this.loanAppActionsMixin_permissions?.hasLo1003Enabled) || !this.loanAppActionsMixin_hasLoanApplication) {
        return ''
      }
      if (this.loanAppActionsMixin_permissions?.hasLo1003Enabled && !this.loanAppActionsMixin_loanApplicationIsMultiphase) {
        let url = `/servicer_loans/${this.loanAppActionsMixin_servicerId}/loan_app/${this.loanAppActionsMixin_loanApp.guid}/edit`
        if (this.loanAppActionsMixin_taskEventId) url += `?eventId=${this.loanAppActionsMixin_taskEventId}`
        return url
      } else {
        const version = this.loanAppActionsMixin_loanAppJson?.version
        const urlBase = version === '3.0' ? 'homehub' : 'borrower'
        let url = `/${urlBase}/custom_form/${this.loanAppActionsMixin_servicerActivationCode}?type=loan_app&guid=${this.loanAppActionsMixin_loanApp.guid}&preview=false`
        if (this.hasSinglePage) url += '&singleApp=true'
        return url
      }
    },
    loanAppActionsMixin_hasLoanApplication () {
      return (this.loanAppActionsMixin_loadingQuery === 0 && !isEmpty(this.loanAppActionsMixin_loanApp) && !this.loanAppActionsMixin_loanApp.deleted)
    },
    loanAppActionsMixin_hasCompletedLoanApplication () {
      return this.loanAppActionsMixin_hasLoanApplication && this.loanAppActionsMixin_loanApp.submitted && this.loanAppActionsMixin_loanApp.phases_complete === this.loanAppActionsMixin_loanApp.total_phases
    },
    loanAppActionsMixin_loanApplicationIsMultiphase () {
      return this.loanAppActionsMixin_hasLoanApplication && this.loanAppActionsMixin_loanApp.total_phases > 1
    },
    loanAppActionsMixin_loanAppJson () {
      if (!this.loanAppActionsMixin_loanApp?.loan_app_json) return null
      return JSON.parse(this.loanAppActionsMixin_loanApp.loan_app_json)
    },
    loanAppActionsMixin_allowRequestBorrowerRevision () {
      return !!(!this.loanAppActionsMixin_loanId || this.loanAppActionsMixin_selectedLoanAppGuid) && (this.loanAppActionsMixin_loanApp?.submitted || this.loanAppActionsMixin_loanApp?.phases_complete > 0) && this.loanAppActionsMixin_permissions?.allowLoanAppReturn
    },
    loanAppActionsMixin_canSendToLos () {
      return (
        !!((this.loanAppActionsMixin_permissions?.allowLoanInfoEdit && !this.loanAppActionsMixin_isRemoteBorrower) || this.loanAppActionsMixin_permissions?.isAdmin) &&
        (
          this.loanAppActionsMixin_losName === 'BYTE' ||
          this.loanAppActionsMixin_losName === 'PC_LENDER' ||
          this.loanAppActionsMixin_losName === 'LENDING_QB' ||
          this.loanAppActionsMixin_losName === 'ENCOMPASS' ||
          this.loanAppActionsMixin_losName === 'MORTGAGE_BOT' ||
          this.loanAppActionsMixin_losName === 'EMPOWER' ||
          !isEmpty(this.loanAppActionsMixin_loanApp?.quantum_reverse_loan)
        ))
    },
    loanAppActionsMixin_submittedToLos () {
      let submitted = false
      if ((this.loanAppActionsMixin_loanApp?.phases_complete >= this.loanAppActionsMixin_loanApp?.total_phases) && this.loanAppActionsMixin_loanApp?.submitted && !this.loanAppActionsMixin_loanApp?.active) {
        submitted = true
      }
      return submitted
    },
    loanAppActionsMixin_allowLoanAppEdit () {
      return this.loanAppActionsMixin_permissions?.allowLoanAppEdit
    },
    loanAppActionsMixin_allowLoanAppAccess () {
      return this.loanAppActionsMixin_permissions?.allowLoanAppAccess
    },
    loanAppActionsMixin_showLoanAppAction () {
      return (this.loanAppActionsMixin_allowLoanAppEdit || this.loanAppActionsMixin_allowLoanAppAccess || this.loanAppActionsMixin_permissions?.isAdmin) && !(!this.loanAppActionsMixin_hasLoanApplication && (this.loanAppActionsMixin_loanId || this.loanAppActionsMixin_loanGuid))
    },
    loanAppActionsMixin_allowLoanConversion () {
      return !!((this.loanAppActionsMixin_permissions?.allowLoanInfoEdit && !this.loanAppActionsMixin_isRemoteBorrower) && this.loanAppActionsMixin_permissions?.isAdmin && !this.loanAppActionsMixin_selectedLoan)
    },
    loanAppActionsMixin_canDeleteLoanApp () {
      return this.loanAppActionsMixin_currentUserAccountType === 'admin' || this.loanAppActionsMixin_permissions?.allowAdminsToDeleteLoanApps
    },
    loanAppActionsMixin_iconButtons () {
      const buttons = []
      if (this.loanAppActionsMixin_canSendToLos && !this.loanAppActionsMixin_submittedToLos) {
        buttons.push({
          icon: '$snExport',
          tooltipText: 'Send to LOS',
          onClick: () => { this.loanAppActionsMixin_showSendLoanAppToLosModal = true }
        })
      }
      if (this.loanAppActionsMixin_allowLoanConversion) {
        buttons.push({
          icon: '$snDocumentMove',
          tooltipText: 'Convert to loan',
          onClick: () => { this.loanAppActionsMixin_showConvertToLoanModal = true }
        })
      }
      if (this.loanAppActionsMixin_canDeleteLoanApp) {
        buttons.push({
          icon: '$snTrash',
          tooltipText: 'Delete',
          onClick: () => { this.loanAppActionsMixin_showDeleteLoanAppModal = true }
        })
      }
      return buttons
    },
    loanAppActionsMixin_viewerActions () {
      const actions = []
      if (this.loanAppActionsMixin_allowRequestBorrowerRevision) {
        actions.push({
          type: 'secondary',
          text: 'Request revision',
          onClick: () => {
            this.loanAppActionsMixin_showRequestRevisionModal = true
          }
        })
      }
      return actions
    }
  },
  methods: {
    ...mapMutations(['setToast']),
    loanAppActionsMixin_handleFailure (action) {
      switch (action) {
        case 'convertToLoan':
          this.loanAppActionsMixin_showConvertToLoanModal = false
          this.loanAppActionsMixin_showLoanAppPreview = false
          this.setToast({
            active: true,
            description: '',
            message: 'There was a problem converting the loan app to a loan.',
            event: 'error'
          })
          break
        case 'deleteLoanApp':
          this.loanAppActionsMixin_showDeleteLoanAppModal = false
          this.loanAppActionsMixin_showLoanAppPreview = false
          this.setToast({
            active: true,
            description: '',
            message: 'There was a problem deleting the loan application',
            event: 'error'
          })
          break
        case 'requestBorrowerRevision':
          this.loanAppActionsMixin_showRequestRevisionModal = false
          this.loanAppActionsMixin_showLoanAppPreview = false
          this.setToast({
            active: true,
            description: '',
            message: 'There was a problem requesting borrower revision.',
            event: 'error'
          })
          break
        case 'sendReminder':
          this.loanAppActionsMixin_showSendReminderModal = false
          this.setToast({
            active: true,
            description: 'Please try again or contact support',
            message: 'Unable to send the reminder',
            event: 'error'
          })
      }
    },
    loanAppActionsMixin_sendReminderSuccess () {
      this.loanAppActionsMixin_showSendReminderModal = false
      this.setToast({
        active: true,
        description: '',
        message: 'Reminder sent!',
        event: 'success'
      })
    },
    async loanAppActionsMixin_handleLoanAppAction () {
      // if the loan app is completed and they do not have the LO 1003 enabled, show the overlay
      if (this.loanAppActionsMixin_hasCompletedLoanApplication && !this?.loanAppActionsMixin_permissions?.hasLo1003Enabled) {
        this.loanAppActionsMixin_showLoanAppPreview = true
        return
      }

      // if no app, create new and refetch its guid for redirect
      if (!this.loanAppActionsMixin_hasLoanApplication) {
        const payload = {
          app_user_guid: this.loanAppActionsMixin_loanOwner?.ownerGuid,
          loan_guid: this.loanAppActionsMixin_loanGuid,
          servicer_profile_guid: this.loanAppActionsMixin_servicerGuid
        }

        await this.$axios.post('/api/v1/user_loan_apps', payload)
        await this.$apollo.queries.loanAppActionsMixin_loanApp.refetch()
      }
      window.open(this.loanAppActionsMixin_loanAppActionHref, '_self')
    },
    loanAppActionsMixin_onClickDownload (format) {
      window.location.replace(`/servicer_leads/export_loan_app/${this.loanAppActionsMixin_servicerId}?app_user_id=${this.loanAppActionsMixin_loanOwner.ownerId}&format=${format}&loan_app_id=${this.loanAppActionsMixin_loanApp.id}`)
      this.loanAppActionsMixin_showDownloadFormatsModal = false
    },
    loanAppActionsMixin_handleSendLoanAppToLos () {
      this.$emit('refetch-loan-app')
      this.loanAppActionsMixin_showSendLoanAppToLosModal = false
      this.loanAppActionsMixin_showLoanAppPreview = false
    },
    loanAppActionsMixin_closeOrRedirect () {
      if (!this.loanAppActionsMixin_loanId) window.location.replace(`/servicer_loans/${this.loanAppActionsMixin_servicerId}/pipeline`)
      else this.loanAppActionsMixin_showLoanAppPreview = false
    }
  }
}
