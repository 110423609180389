import snToastService from '~/services/snToastService'

export default {
  setServicerProfile (state, payload) {
    state.servicerProfile = payload
  },
  setServicerEmail (state, payload) {
    state.servicerEmail = payload
  },
  setLoanOwner (state, owner) {
    const loanOwner = {
      ownerId: owner?.id,
      ownerGuid: owner?.guid,
      ownerType: owner?.__typename,
      firstName: owner?.firstName,
      lastName: owner?.lastName,
      fullName: owner?.fullName || 'Unnamed borrower',
      email: owner?.appUser?.email || owner?.email, // every time we are asked to flip how this is calculated up the counter: 2 times
      phone: owner?.phone,
      userId: owner?.user?.id ?? '',
      appUserGuid: owner?.appUser?.guid ?? ''
    }
    state.loanOwner = loanOwner
  },
  setLoanOwnerName (state, loanOwnerName) {
    state.loanOwnerName = loanOwnerName
  },
  setServicerActivationCode (state, activationCode) {
    state.servicerActivationCode = activationCode
  },
  setSupportPhone (state, supportPhone) {
    state.supportPhone = supportPhone
  },
  setTeamMemberGuid (state, teamMemberGuid) {
    state.teamMemberGuid = teamMemberGuid
  },
  setTeamMemberId (state, id) {
    state.teamMemberId = id
  },
  setLosName (state, name) {
    state.losName = name
  },
  setDocStorageLocation (state, docStorageLocation) {
    state.docStorageLocation = docStorageLocation
  },
  setIsRemoteBorrower (state, isRemoteBorrower) {
    state.isRemoteBorrower = isRemoteBorrower
  },
  setBorrower (state, borrower) {
    state.borrower = borrower
  },
  setCoborrower (state, coBorrower) {
    state.coBorrower = coBorrower
  },
  setSelectedLoan (state, loan) {
    state.selectedLoan = loan
  },
  setSelectedLoanApp (state, loanApp) {
    state.selectedLoanApp = loanApp
  },
  setLoadingLoanApplication (state, isLoading) {
    state.loadingLoanApplication = isLoading
  },
  setLoadingStoreSetup (state, isLoading) {
    state.loadingStoreSetup = isLoading
  },
  setCurrentUser (state, user) {
    state.currentUser = user
  },
  setToast (state, toast) {
    const initialToast = {
      active: false,
      type: 'simple',
      description: '',
      event: null,
      message: '',
      actionText: '',
      actionVariables: {}
    }
    snToastService.setToast(state, { ...initialToast, ...toast })
  },
  removeToasts (state) {
    state._toasts = []
  },
  removeToast (state, toastId) {
    snToastService.removeToast(state, toastId)
  },
  updatePermissions (state, permissions) {
    state.permissions = {
      ...state.permissions,
      ...permissions
    }
  },
  updateLoanDocVisibility (state, loanDocVisibilityEnabled) {
    state.selectedLoan.borrower_loan_doc_visibility_enabled = loanDocVisibilityEnabled
  },
  setRefreshLoanDocs (state, refreshLoanDocs) {
    state.refreshLoanDocs = refreshLoanDocs
  },
  updatePrimaryLoa (state, primaryLoa) {
    if (state.selectedLoan) {
      state.selectedLoan.primaryLoa = primaryLoa
    }
  },
  setLoadingLoan (state, loading) {
    state.loadingLoan = loading
  },
  setLoanBorrowersForLoan (state, loanBorrowers) {
    state.selectedLoan.loan_borrowers = loanBorrowers
  },
  setLoanBorrowersForLoanApp (state, loanBorrowers) {
    state.selectedLoanApp.loan_borrowers = loanBorrowers
  },
  setLoadingTasks (state, loading) {
    state.loadingTasks = loading
  },
  setLoanOfficerTasksForLoan (state, tasks) {
    state.selectedLoan.loanOfficerTasks = tasks
  },
  setLoanOfficerTasksForLoanApp (state, tasks) {
    state.selectedLoanApp.loanOfficerTasks = tasks
  },
  setThirdPartyTasksForLoan (state, list) {
    state.selectedLoan.thirdPartyRecipientsAndTasksList = list
  },
  setLoanApplications (state, loanApplications) {
    state.loanApplications = loanApplications
  }
}
